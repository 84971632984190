<template>
  <div>
    <div class="footerContainer">
      <footer class="container" v-if="info.companyInfo">
        <div class="ft">
          <p>{{ $t('Kategoriler') }}</p>
          <router-link v-for="item in info.menu" :key="item.id" :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">{{ item.title }}</router-link>
        </div>
        <div class="ft">
          <p>{{ $t('Kurumsal') }}</p>
          <router-link v-for="item in info.kurumsal" :key="item.id" :to="{ name: 'Page', params: { id: item.id + '-' + $fnc.seo(item.title), parent: item.cat_id }}">{{ item.title }}</router-link>
        </div>
        <div class="ftr">
          <p>{{ $t('Bizi Takip Edin') }}</p>
          <a v-for="item in info.socials" :key="item.id" rel="noopener" :href="item.paramValue" target="_blank" v-html="item.paramTitle"></a>
        </div>
        <div class="ftr">
          <p>{{ $t('İletişim') }}</p>
          <router-link :to="{ name: 'Contacts' }">{{ info.companyInfo.PAGE_ADRES }}</router-link>
          <router-link :to="{ name: 'Contacts' }">{{ info.companyInfo.PAGE_PHONE }}</router-link>
        </div>
      </footer>
      <div class="copy">
        <div class="container">
          <a rel="noopener" target="_blank" href="https://www.ahmetayoglu.com/">
            <img src="@/assets/logo-ayoglu.png" width="180" alt="Ahmet Ayoğlu Markasıdır" title="Ahmet Ayoğlu Markasıdır">
          </a>
          <span><img src="@/assets/logo_band_white.svg" width="320"></span>
        </div>
        <div class="container" style="display:grid;grid-template-columns:1fr;place-items:center;borders;padding-top:20px;padding-bottom:0px;margin-bottom:0px">
          <a rel="noopener" target="_blank" href="https://www.kareiki.com/">2021 © Kare iKi </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import FixedNavbar from '@/components/FixedNavbar'

export default {
  // components: { FixedNavbar },
  props: ['info']
}
</script>

<style scoped lang="scss">
.footerContainer{
  background:#222;
  padding-top: 30px;
}
.copy {
  // background:var(--color1);
  padding-bottom: 30px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-top:1px solid #535353;
    margin-top: 20px;
    padding-top: 20px;
    a {
      color:#e4e4e4;
    }
    a:hover {
      color:#fff;
    }
    font-size: 13px;
  }
}

footer {
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  gap:0px;
  .ft, .ftr {
    p {
      font-size: 14px;
      color: #e4e4e4;
      font-weight: bold;
      padding-bottom: 4px;
    }
  }
}
a {
  font-size: 13px;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  color:#bbbbbb;
}
a:hover {
  color:#fff;
}
@media screen and (max-width: 768px) {
  footer {
    grid-template-columns: repeat(2, 1fr);
    .ft {
      margin-bottom: 42px;
    }
  }
  .copy {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 20px;
    div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      place-items: center;
      a {
        text-align: center;
        font-size: 13px;
      }
      img {
        width:calc(100%);
        margin-top: 16px;
      }
    }
  }
}
</style>
