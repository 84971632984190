<template>
  <div class="priceBox" v-if="!$parent.isLoading && totalPrice > 0 && $parent.max > 0">
    <div><b style="font-size: 12px;color: #666;font-weight: bold;">{{ $t('Toplam') }}</b></div>
    <div style="color: #777;">{{ $t('KDV Dahil') }}</div>
    <div class="bigPrice" v-if="totalPrice > 0">
      {{ totalPrice.toFixed(2) }}
      <span style="font-size: 23px;margin-left:3px;font-weight: bold;">TL</span></div>
    <div class="kargo">{{ $t('Ücretsiz Kargo') }}</div>
    <div v-if="!isSaving">
      <button v-if="$parent.max > 0" @click="addToCart" class="sptButton2"><i class="fa fa-shopping-basket" style="font-size: 16px;"></i> {{ $t('Sepete Ekle') }}</button>
      <button v-else class="sptButton2"><i class="fa fa-minus-circle" style="font-size: 16px;"></i> {{ $t('Stokta yok') }}</button>
    </div>
    <div v-else>
      <button class="sptButton2" style="cursor:default;background-color:red"><span class="loadingCart"></span>{{ $t('Ekleniyor') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['totalPrice', 'isSaving'],
  methods: {
    addToCart: function () {
      this.$parent.addToCart()
    }
  },
  watch: {
    $route (to, from) {
      this.$parent.calculate()
    }
  }
}
</script>

<style scoped lang="scss">
.priceBox {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-top:12px;
  height: 180px;
  place-items: center;
  width: 100%;
  // box-shadow: 0px 40px 40px -20px rgba(0, 0, 0, 0.26);
  div{
    font-size: 11px;
    text-align:center;
    line-height: 13px;
  }
}
@media screen and (max-width:768px) {
  .priceBox {
    box-shadow: 0px 20px 20px -20px rgba(0, 0, 0, 0.26);
    position: unset;
    height: unset;
  }
}
.sptButton2{
  display: flex;
  place-items: center;
  gap: 5px;
  justify-content: center;
  background: var(--color1);
  border: none;
  outline: none;
  font-size: 18px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 22px;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}
.sptButton2:hover {
  background: var(--color2);
}
.topFiyat {
  font-size: 11px;
}
.bigPrice {
  margin-top: 6px;
  font-size: 30px !important;
  color: #444;
  font-weight: bold;
  line-height: 40px !important;
}
.kargo {
  margin-top: 3px;
  margin-bottom: 12px;
  color: #226e4b;
  font-size: 13px !important;
  font-weight: bold;
}
.loadingCart {
  background:url(~@/assets/loading-cart.gif) center no-repeat;
  display:inline-block;
  width:40px;
  height:18px;
}
</style>
