<template>
  <div v-if="item" class="container flex">
    <div v-for="banner in item.slides" :key="banner.id">
      <router-link :to="banner.url">
        <img loading="lazy" :src="fileCdn + banner.image" :alt="banner.title" :title="banner.title" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      fileCdn: this.$store.state.fileCdn + '/'
    }
  }
}
</script>

<style scoped lang="scss">
.flex {
  display:flex;
  gap: 20px;
  margin-bottom: 20px;
  img {
    width:100%;
    border-radius: 15px;
  }
}
@media screen and (max-width: 768px) {
  .flex {
    display: grid;
    gap: 20px;
    img {
      width:100%;
    }
  }
}
</style>
