<template>
  <div class="container" style="margin-top:20px;border-radius:12px; overflow:hidden">
    <splide :options="item.options">
      <splide-slide v-for="item in item.slides" :key="item.image">
        <router-link v-if="item.url" :to="item.url">
          <img loading="lazy" width="100%" :alt="item.title" :src="fileCdn + item.image">
        </router-link>
        <img loading="lazy" width="100%" v-else :alt="item.title" :src="fileCdn + item.image">
      </splide-slide>
    </splide>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      fileCdn: this.$store.state.fileCdn
    }
  }
}
</script>
