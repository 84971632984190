<template>
  <div>
    <h1 class="productTitle">{{ product.title }}</h1>
    <p style="font-size:13px">{{ product.subTitle || product.altBaslik }} <i>SKU: {{ product.sku }}</i></p>
    <div v-if="!isLoading" class="productDetail">
      <div>
        <div v-if="product.menuInfo" style="font-size: 12px;">
           {{ product.description }}
        </div>
        <div style="margin-bottom:12px; background:#fff;padding:15px;margin-top:12px" v-if="product.combinations.length > 1">
          <b>Diğer Seçenekler</b>
          <div class="combs">
            <div :class="{ 'active': item.urunlerBarkod === product.barcode, 'nope': item.stock < 1 }" v-for="item in product.combinations" :key="item.urunlerId">
              <router-link :to="{ name: 'Product', params: {pid: item.urunlerId + '-' + $fnc.seo(item.urunlerAdi) } } ">
                <img :title="item.urunlerAdi" v-if="item.stock > 0" :src="mediaCdn + item.image" width="100%">
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="adets" v-if="max > 0">
          <h2 style="text-align: center;font-weight: bold;">{{ $tc('termin', product.termin) }}</h2>
          <div class="qtyRow">
            <p>{{ $t('Miktar') }}</p>
            <div class="qtySelector">
              <button @click="decrease"><i class="fa fa-minus"></i></button>
              <input @change="validate" v-model.number="qty" min="1" :max="max - stockInCart" type="tel">
              <button @click="increase"><i class="fa fa-plus"></i></button>
            </div>
          </div>
          <p style="color:red;margin-top:12px;font-weight:bold;text-align:center" v-if="max < 4">{{ $tc('StokUyarı', max) }}</p>
          <p style="color:green; font-weight:bold;text-align:center" v-if="stockInCart > 0">{{ $tc('sepetteVar', stockInCart) }}</p>
        </div>
        <div style="font-size: 12px;margin-top: 10px;" v-if="max > 0">
          {{ $tc('product_detail_sub', product.termin) }}
        </div>
        <div v-else>{{ $t('Bu ürün şu anda temin edilememektedir') }}</div>
      </div>
      <div>
        <ProductPriceNormal :totalPrice="totalPrice" :vat="product.kdvOran" :isSaving="isSaving" :max="product.avl" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ProductPriceNormal from '@/small-components/ProductPriceNormal.vue'

export default {
  props: ['product'],
  components: { ProductPriceNormal },
  data: function () {
    return {
      pid: this.product.id,
      bid: 0,
      isLoading: false,
      isSaving: false,
      productType: '',
      selectedVariant: null,
      kdv: 0,
      termin: 0,
      price: 0,
      totalPrice: 0,
      qty: 1,
      max: this.product.avl,
      stockInCart: 0,
      mediaCdn: this.$store.state.mediaCdn
    }
  },
  mounted () {
    if (this.product.variants) {
      const variants = this.product.variants
      const found = variants.find(element => element.stock > 0)
      if (found) {
        this.selectVariant(found)
      }
    } else {
      this.selectedVariant = null
      this.max = this.product.avl
      this.totalPrice = this.product.price
      this.stockInCart = this.product.stockInCart
      this.calculate()
    }
  },
  watch: {
    $route (to, from) {
      this.qty = 1
      setTimeout(() => {
        this.calculate()
      }, 100)
    }
  },
  methods: {
    validate: function () {
      if (this.max === 0) {
        this.$toast.warning('Bu ürün şu anda tedarik edilememektedir')
        return
      }
      if (this.qty < 1) {
        this.qty = 1
        this.$toast.warning('En az 1 adet ekleyebilirsiniz')
      }
      if (this.qty > this.max) {
        this.qty = this.max
        this.$toast.warning('En fazla ' + (this.max) + ' adet ekleyebilirsiniz')
      }
      if (!Number.isInteger(this.qty)) {
        // this.$toast.warning('notInteger')
        this.qty = 1
      }
      this.calculate()
    },
    increase: function () {
      if (this.qty < this.max) {
        this.qty = this.qty + 1
        this.calculate()
      }
    },
    decrease: function () {
      if (this.qty > 1) {
        this.qty = this.qty - 1
        this.calculate()
      }
    },
    calculate: function () {
      if (this.selectedVariant) {
        this.totalPrice = this.selectedVariant.price
        this.max = this.selectedVariant.stock
        this.pid = this.product.id
        this.bid = this.selectedVariant.id
      } else {
        this.totalPrice = this.product.price
        this.max = this.product.avl
        this.pid = this.product.id
      }
    },
    addToCart: async function (qty) {
      this.isSaving = true
      const payload = {
        pid: parseInt(this.pid),
        bid: parseInt(this.bid),
        qty: parseInt(this.qty),
        productType: this.productType,
        user: this.$store.state.user
      }
      await axios.post('api/addToCart.php', payload).then(response => {
        setTimeout(() => {
          this.$store.dispatch('setCart', response.data.cartCount)
          this.isSaving = false
          /* setTimeout(() => {
            this.$parent.itemDetails()
          }, 250) */
        }, 500)
        const items = [{
          id: 'p' + this.pid + 'b' + 0,
          name: this.product.title.trim(),
          brand: this.product.brandName.trim(),
          category: this.product.googleCategoryName.trim(),
          variant: '',
          quantity: this.qty,
          price: this.totalPrice
        }]
        this.$gtag.event('add_to_cart', { items })
      })
    },
    selectVariant: function (variant) {
      if (variant.stock < 1) {
        return
      }
      if (this.selectedVariant !== variant) {
        this.qty = 1
      }
      this.selectedVariant = variant
      this.stockInCart = this.selectedVariant.stockInCart
      if (this.selectedVariant.images.length > 0) {
        this.$parent.product.images = this.selectedVariant.images
      } else {
        this.$parent.product.images = this.product.fullImages
      }
      this.calculate()
    }
  }
}
</script>

<style scoped lang="scss">
.combs {
  display:grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top:8px;
  border-radius: 12px;
  a {
    border: 1px solid #ccc;
    display:grid;
    place-items: center;
    font-size:11px;
    color:#666;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
  }
  .active {
    border: 1px solid var(--color1);
  }
  .nope {
    display:none;
  }
}
.qtyRow {
  margin-top: 20px;
  display:grid;justify-content: center;align-items:center;
  gap:12px;
  p {
    text-align: center;
  }
  .qtySelector {
    display: grid;
    grid-template-columns:28px 80px 28px;
    height: 30px;
    // border: 1px solid #ccc;
    background: #ccc;
    border-radius: 6px;
    overflow: hidden;
    align-items: center;
    button {
      display: grid;
      place-items: center;
      height: 100%;
      border: none;
      width: 100%;
      background: #dadada;
      i {
        color:#666;
      }
    }
    button:hover {
      background: var(--color1);
      i {
        color:#fff;
      }
    }
    input {
      border:none;
      border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      height: 100%;
      text-align:center;
    }
    input:focus {
      box-shadow: unset;
    }
  }
}
.productDetail {
  display:grid;
  grid-template-columns:auto 200px;
  height: 100%;
  gap:20px
}
.productTitle {
  font-weight: bold;
  color:var(--black1);
  text-align: left;
  font-size:1.3rem;
  font-display: swap;
  padding-bottom: 10px;
  user-select: text;
}
@media screen and (max-width:768px) {
  .productDetail {
    grid-template-columns: 1fr;
    height: unset;
  }
}
.selections {
  display:grid;
  grid-template-columns: 1fr;
  place-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  div {
    width: 100%;
    font-size: 11px;
    font-weight: bold;
  }
  .buttonset {
    display:flex;
    align-content: center;
    gap:10px;
  }
  .title {
    display: grid;
    grid-template-columns:1fr;
    align-items: center;
    color: #555;
  }
  .title i {
    display:none;
  }
}
.buttonset button {
  width: 100%;
  border:2px dashed #ccc;
  color: grey;
  background: #fff;
  border-radius: 4px;
  font-size: 11px;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0px;
  outline: none;
  display: grid;
  gap: 2px;
  place-items: center;
  box-shadow:0px 4px 12px -8px rgba(0, 0, 0, 0.4) !important;
}
.buttonset button i {
  font-size: 26px;
  margin-top: 4px;
}
.buttonset button:hover {
  border: 2px solid rgb(136, 136, 136);
  color: black;
}
.buttonset button:focus, .buttonset button:active, .activeButton {
  border: 2px solid #40bbf4 !important;
  color: black !important;
}
.adets {
  background:#fafafa;
  padding: 15px;
  font-size: 12px;
  margin-top: 10px;
  border-radius: 6px;
}
.adetRow {
  display: grid;
  grid-template-columns: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.options {
  display: flex;
  gap:12px;
  margin-top: 8px;
  div {
    user-select: none;
    border:2px solid #ccc;
    display: grid;
    place-items: center;
    gap: 0px;
    font-weight: bold;
    margin:0px;
    padding:4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    cursor: pointer;
  }
  div.active {
    border: 2px solid var(--color1);
    color: var(--color1);
  }
  div.notInStock {
    border-color: #bebebe;
    color: #bebebe;
    text-decoration: line-through;
    cursor: not-allowed;
  }
}
.adetci {
  display: grid;
  place-items: center;
  gap: 0px;
  font-size: 10px !important;
  font-weight: bold;
  margin:0px;
  padding:0px;
}
.rakamci {
  text-align: center;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #d4d4d4;
  cursor: pointer;
  transition: all 0.3s;
}
.rakamci .kdvHaric {
  font-size: 20px !important;
  font-weight: bold;
  line-height:20px;
}
.rakamci .kdvDahil{
  font-size: 11px !important;
  font-weight: bold;
  line-height:11px;
}
.rakamci:hover {
  border: 2px solid #979797;
}
.rakam {
  font-weight: bold;
  font-size: 13px;
  line-height: 13px !important;
}
.adet {
  font-size: 11px;
  line-height: 11px !important;
}
</style>
