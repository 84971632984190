<template>
  <div>
    <header class="container" v-if="$store.state.width > 1024" >
      <div class="row1">
        <div class="right">
          <div class="right" v-if="$store.state.user.userId">
            <router-link class="primary" :to="{ name: 'MyAccountOrders' }"><i class="fa fa-user-lock"></i> {{ $t('Hesabım') }}</router-link>
          </div>
          <div class="right" v-else>
            <router-link class="primary" :to="{ name: 'Login' }"><i class="fa fa-key"></i> {{ $t('Giriş Yapın') }}</router-link>
            <router-link :to="{ name: 'Register' }"><i class="fa fa-user-plus"></i> {{ $t('Üye Olun') }}</router-link>
          </div>
        </div>
        <router-link :alt="$tc('Anasayfa')" :title="$tc('Anasayfa')" :to="{ name: 'Index' }">
          <div class="logo"></div>
        </router-link>
        <div class="searchContainer" style="margin-right:14px;">
          <input name="search" autocomplete="off" placeholder="Arayın" @keydown.enter="makeSearch" type="text" v-model.trim="searchField">
        </div>
        <div>
          <router-link :to="{ name: 'Cart' }" class="cartButton bs">
            <div class="cartIcon"></div>
            <div class="cartCount" v-if="$store.state.cart > 0">{{ $store.state.cart }}</div>
          </router-link>
        </div>
      </div>
      <nav class="container">
        <router-link :to="{ name: 'Index' }">
          <i class="fa fa-home"></i>
          Anasayfa</router-link>
        <router-link class=""
          v-for="item in info.menu"
          :key="item.id"
          :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">
          <i class="fa fa-circle-notch"></i>
          {{ item.title }}
        </router-link>
        <router-link :to="{ name: 'Page', params: { id: 4 + '-hakkimizda', parent: 1} }">
          <i class="fa fa-user"></i>
          {{ $t('Hakkımızda') }}</router-link>
        <router-link :to="{ name: 'Contacts' }">
          <i class="fa fa-phone-alt"></i>
          {{ $t('İletişim') }}</router-link>
        <router-link :to="{ name: 'MyAccountOrders' }">
          <i class="fa fa-truck"></i>
          {{ $t('Sipariş Takibi') }}</router-link>
      </nav>
    </header>
    <header class="container" v-else>
      <div style="display:grid;place-items:center; width:100%;">
        <router-link :alt="$tc('Anasayfa')" :title="$tc('Anasayfa')" :to="{ name: 'Index' }">
          <div class="logo"></div>
        </router-link>
      </div>
    </header>
    <div class="container secondRow" v-if="$store.state.width < 1024">
      <div>
        <a @click="toggleMenu(true)" class="cartButton catsButton bs">
          <i class="fa fa-list-ul" style="text-align:center;margin-left:8px;"></i>
        </a>
        <transition name="fadasde2">
          <div v-if="$store.state.catMenu">
            <ul class="menu container" @mouseleave="toggleMenu(false)">
              <div class="cont">
                <li v-for="item in menu" :key="item.id">
                  <router-link @click="toggleMenu(false)" :to="{ name: 'Products', params: { id: item.id, top: 0 } }">
                    {{ item.title }}
                  </router-link>
                </li>
                <ul style="border-top:1px solid #ccc;"  v-if="$store.state.width < 768">
                  <li><router-link :to="{ name: 'Page', params: { id: 4 + '-hakkimizda', parent: 1} }">{{ $t('Hakkımızda') }}</router-link></li>
                  <li><router-link :to="{ name: 'Contacts' }">{{ $t('İletişim') }}</router-link></li>
                  <li><router-link :to="{ name: 'MyAccountOrders' }">{{ $t('Sipariş Takibi') }}</router-link></li>
                </ul>
                <ul style="border-top:1px solid #ccc;"  v-if="$store.state.width < 768">
                  <div v-if="!$store.state.user.userId">
                    <li><router-link :to="{ name: 'Login' }">{{ $t('Giriş Yapın') }}</router-link></li>
                    <li><router-link :to="{ name: 'Register' }">{{ $t('Üye Olun') }}</router-link></li>
                  </div>
                  <div v-else>
                    <li><router-link :to="{ name: 'MyAccount' }">{{ $t('Hesabım') }}</router-link></li>
                  </div>
                </ul>
              </div>
            </ul>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="$store.state.catMenu" @click.self="toggleMenu(false)" class="overlay"></div>
        </transition>
      </div>
      <div class="searchContainer">
        <input name="search" autocomplete="off" placeholder="Arayın" @keydown.enter="makeSearch" type="text" v-model.trim="searchField">
      </div>
      <div>
        <router-link :to="{ name: 'Cart' }" class="cartButton bs">
          <div class="cartIcon"></div>
          <div class="cartCount" v-if="$store.state.cart > 0">{{ $store.state.cart }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      subMenu: false,
      menuOpen: false,
      menu: this.info.menu,
      promo: this.$store.state.info.topPromoDesktop,
      searchField: ''
    }
  },
  mounted () {
    if (this.$route.params.search) {
      this.searchField = this.$route.params.search
    }
  },
  methods: {
    toggleMenu: function (state) {
      this.$store.dispatch('setCatMenu', state)
    },
    makeSearch: function () {
      const s = this.searchField.trim()
      if (s.length > 1) {
        this.$router.push({ name: 'Search', params: { search: s } })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.topMenuContainer {
  display:flex;
  a {
    margin-right:14px;
  }
  a:last-child{
    margin-right: 0px;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.overlay {
  background:rgba(0,0,0,0.3); width:100%; height:100%; position:absolute; z-index:2; top:0; left:0;
}
.menu {
  position:absolute;
  z-index: 3;
  top: 60px;
  .cont {
    box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.2);
    padding:13px;
    border-radius:12px;
    background: #fff;
    margin-top: 72px;
  }
  li {
    list-style:none;
    a {
      display:block;
      text-align:left;
      padding:12px;
      transition: 0.1s ease-in all;
    }
  }
}
.bs {
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
  transition: all 0.2s ease-out;
}
.bs:hover, .bs:focus {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.22);
}
.catsButton {
  width: 100% !important;
  font-size:14px;
  border-radius:20px;
  letter-spacing:1px;
  text-transform:unset;
  grid-template-columns: 34px auto;
  align-items:center;
  justify-content:left;
}
.cartIcon {
  width:40px;
  height:40px;
  display: block;
  mask: url(../assets/icons/cart.svg) no-repeat;
  mask-size: 20px;
  mask-position: center;
  background-color: #000;
}
.cartCount {
  font-family: var(--font2);
  position: absolute;
  margin-left: 26px;
  background: var(--color1);
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  display: grid;
  place-content: center;
}
.searchContainer {
  input {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
    background: white;
    border: none;
    height: 40px;
    padding:16px;
    width:100%;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.2s ease-out;
    padding-left: 40px;
    background: url(../assets/icons/mag.svg) no-repeat;
    background-size: 16px;
    background-position: 16px 12px;
    background-color: #fff;
  }
  input:focus, input:hover {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.22);
  }
  input::placeholder {
    color: #c0c0c0;
  }
}
.cartButton {
  background: #fff;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 20px;
  font-size: 16px;
  i {
    transition: all 0.2s ease-out;
    color: #666;
  }
}
.cartButton:hover {
  i {
    color: var(--color1);
  }
}
a {
  font-family: var(--font1);
  color: black;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.04px;
  text-align: center;
  transition: all 0.2s ease-out;
  white-space: nowrap;
}
a:hover, a.active {
  color: var(--color1);
}
.logo {
  margin:auto;
  width: 280px;
  height: 70px;
  display: grid;
  align-items:center;
  background: url('../assets/logo.png') center no-repeat;
  background-size: contain;
}
@media screen and (max-width: 1024px) {
  .logo {
    width: 240px;
    height: 60px;
    margin: auto;
  }
}
header {
  padding-top: 30px;
  display: grid;
  align-content: center;
  justify-items: left;
  .row1{
    display:grid;
    grid-template-columns: 260px auto 220px 40px;
    width: 100%;
    align-items: center;
  }
  nav {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px;
    gap: 40px;
    border-top:1px solid #e0e0e0;
    border-bottom:1px solid #e0e0e0;
    a {
      font-size: 14px;
      font-weight: 400;
      i {
        color: #666;
      }
    }
    a:hover, a.active {
      i {
        color: var(--color1);
      }
    }
}
  .right {
    width: 100%;
    display: flex;
    gap: 15px;
    place-items: center;
    justify-content: flex-start;
    .primary {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;
      background: var(--color1);
      color: white;
      border-radius: 32px;
      font-size: 15px;
      font-weight: 600;
    }
    .primary:hover {
      background-color: var(--color1);
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
    }
  }
}
.sticky {
  width: calc(100% - 40px);
  margin-left: 20px;
  position: sticky !important;
  top: 20px;
  z-index: 4;
}
@media screen and (max-width:1024px) {
  .secondRow {
    display: grid;
    grid-template-columns: 40px auto 40px;
    gap: 15px;
    margin-top:20px;
  }
  .menu {
    .cont {
      margin-top:0px;
    }
  }
  .overlay {
    backdrop-filter: blur(20px);
  }
}
</style>
