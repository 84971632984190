<template>
  <div v-if="item" class="container">
    <div class="banner">
      <router-link v-if="item.url" :to="item.url">
        <img :src="fileCdn + item.image" :alt="item.title" width="100%">
      </router-link>
      <img v-else :src="fileCdn + item.image" :alt="item.title" width="100%">
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data () {
    return {
      fileCdn: this.$store.state.fileCdn + '/'
    }
  }
}
</script>

<style scoped>
.banner {
  margin-top: 20px;
  margin-bottom: 20px;
}
img {
  width: 100%;
  border-radius: 12px;
}
</style>
