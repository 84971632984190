<template>
  <div>
    <div class="container" style="min-height:850px" v-if="!isLoading && !error">
      <BreadCrumb :info="breadCrumb" v-if="showBc" />
      <Bcjsonld :jsonld = "jsonld" />
      <div class="pContainer">
        <div>
          <img style="margin-bottom:30px;border-radius:12px;background:#fff;" v-for="image in product.images" :key="image.id" :src="mediaCdn + '/2000_' + image" width="100%">
        </div>
        <div>
          <div style="position:sticky; top:20px">
            <NormalProduct2 :product="product" v-if="productType === 'normal'" />
            <ProductSpecs :specs="product.specs" style="margin-top:20px;display:none" />
          </div>
        </div>
      </div>
      <div class="tabs" v-if="tabs">
        <div class="tabNavContainer">
          <div class="tabNav" style="min-width:600px">
            <button
            @click="selectTab(item)"
            v-for="item in tabs" :key="item.id" :class="{ 'tabActive': selectedTab.id === item.id }">{{ item.title }}</button>
          </div>
        </div>
        <div class="tabContent" style="padding:10px">
          <div v-for="item in tabs" :key="item.id">
            <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-if="item.type === 'comments'">
              <div v-if="reviews.length > 0">
                <div v-for="item in reviews" :key="item.id" class="comment-row">
                  <div class="avatar">{{ item.user_name.split('')[0] }}</div>
                  <div class="comment-bubble">
                    <div class="person">
                      <span>{{ item.user_name }}</span>
                      <span style="font-size:11px;text-align:right;color:#666">{{ $fnc.tarih(item.created_at) }}</span>
                    </div>
                    <star-rating :round-start-rating="false" v-model="item.rating" :star-size="14" :show-rating="false" :read-only="true"></star-rating>
                    <div v-if="item.comment" class="comment" v-html="$fnc.nl2br(item.comment)"></div>
                  </div>
                </div>
              </div>
              <div v-else>Şu anda bu ürüne hiç yorum yapılmamış</div>
            </div>
            <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else-if="item.type === 'taksit'">Taksit Seçenekleri</div>
            <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else-if="item.type === 'jsonPage'">
              <JsonPageNormal :page="item.content" />
            </div>
            <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else>
              <div v-html="item.content"></div>
              <ProductSpecs style="padding:0px;margin-top:12px" :specs="product.specs" />
            </div>
          </div>
        </div>
      </div>
      <Collection v-if="product.relatedProducts" :item="!product.relatedProducts" />
    </div>
    <div v-else style="min-height:850px; display:grid;place-items:center">
      <img src="~@/assets/loading-pulse.svg">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import ProductImages from '@/small-components/ProductImages.vue'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import NormalProduct2 from '@/components/NormalProduct2.vue'
import ProductSpecs from '@/small-components/ProductSpecs.vue'
import Collection from '@/home-components/Collection.vue'
import Bcjsonld from '@/small-components/Bcjsonld'

export default {
  components: { BreadCrumb, NormalProduct2, ProductSpecs, Collection, Bcjsonld },
  metaInfo () {
    return {
      title: this.product.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.product.title }
      ]
    }
  },
  data: function () {
    return {
      pid: this.$route.params.pid,
      mediaCdn: this.$store.state.mediaCdn,
      isLoading: false,
      isSaving: false,
      productType: '',
      product: '',
      variants: [],
      variantGroup: null,
      productPrice: 0,
      carpan: null,
      kdvOran: null,
      kdv: 0,
      termin: null,
      price: 0,
      totalPrice: 0,
      breadCrumb: [],
      jsonld: null,
      error: null,
      showBc: true,
      rating: [],
      reviews: [],
      tabs: null,
      selectedTab: {
        id: 1,
        title: ''
      }
    }
  },
  beforeMount () {
    this.itemDetails()
  },
  watch: {
    $route (to, from) {
      const hash = to.hash
      const fromHash = from.hash
      if ((!hash) && (!fromHash)) {
        this.pid = to.params.pid
        this.itemDetails()
      }
    }
  },
  methods: {
    selectTab: function (item) {
      this.selectedTab = item
    },
    itemDetails: async function () {
      this.isLoading = true
      const payload = {
        pid: this.pid,
        user: this.$store.state.user
      }
      await axios.post('api/template.php', payload).then(response => {
        if (response.data.error === 'none') {
          this.productType = response.data.productType
          this.breadCrumb = response.data.bc
          this.product = response.data.product
          this.rating = response.data.rating
          this.reviews = response.data.reviews
          this.tabs = response.data.tabs
          this.isLoading = false
          this.$fnc.pageView(this)
          const images = this.product.images.map(
            x => {
              return this.$store.state.mediaCdn + '1500_' + x
            }
          )
          const jsonld = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: this.product.title,
            image: images,
            description: this.product.description,
            sku: this.product.sku,
            gtin13: this.product.barcode,
            brand: {
              '@type': 'Brand',
              name: this.product.brandName
            },
            offers: {
              '@type': 'Offer',
              url: window.location.href,
              priceCurrency: 'TRY',
              price: this.product.price,
              priceValidUntil: this.product.validUntil,
              itemCondition: 'https://schema.org/NewCondition',
              availability: 'https://schema.org/InStock',
              seller: {
                '@type': 'Organization',
                name: this.$store.state.baseUrl
              }
            }
          }
          this.jsonld = jsonld
          this.error = null
        } else {
          this.error = response.data.error
          setTimeout(() => {
            this.$router.push({ name: '404', params: { catchAll: this.$i18n.t(this.$fnc.seo(response.data.error)) } })
          }, 400)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.ql-align-center {
  text-align: center;
}
.tabHtml {
  ul {
    padding-left: 14px;
    margin: 0;
  }
  h3 {
    margin-bottom: 12px;
  }
  p {}
}
</style>

<style scoped lang="scss">
.person {
  display: grid;
  grid-template-columns: auto 90px;
  font-weight: bold;
  align-items: center;
}
.comment-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  align-items: flex-start;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ecebf3;
  margin-right: 10px;
  display: grid;
  place-items: center;
  font-weight: bold;
  color:#727272;
}
// comment bubble
.comment-bubble {
  position: relative;
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 0px;
}
.comment {
  padding: 6px;
}
.tabs {
  border:1px solid var(--color1);
  border-radius: 4px;
  padding: 10px;
  margin-top: 70px;
  margin-bottom: 20px;
  background:#fff;
  font-family: var(--font2);
  .tabNavContainer {
    width: 100%;
    overflow-y: auto;
  }
  .tabNav {
    display: flex;
    gap: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #cecece;
    button{
      border:none;
      background:none;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 3px;
      font-size: 14px;
    }
    .tabActive, button:hover {
      background: var(--color1);
      color: white;
    }
  }
}
.hidden {
  display:none;
}
.visible {
  display:block;
}
.pContainer {
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width:768px) {
  .pContainer {
    grid-template-columns: 1fr;
    gap:12px;
  }
}
</style>
