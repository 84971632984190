export const fnc = {
  seo: (url) => {
    return url.toString().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
      .replace(/&/g, '-and-')
      .replace(/ı/g, 'i')
      .replace(/[^a-z0-9-]/g, '')
      .replace(/-+/g, '-')
      .replace(/^-*/, '')
      .replace(/-*$/, '')
  },
  getWidth: () => {
    return window.innerWidth
  },
  getHeight: () => {
    return document.body.scrollHeight
  },
  pageRange: (current, { min = 1, total = 20, length = 7 } = {}) => {
    if (length > total) length = total
    let start = current - Math.floor(length / 2)
    start = Math.max(start, min)
    start = Math.min(start, min + total - length)
    return Array.from({ length: length }, (el, i) => start + i)
  },
  phone: (str) => {
    if (!str) {
      return
    }
    var cleaned = ('' + str).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + ' ' + match[3] + ' ' + match[4]
    }
    return str
  },
  pageView: (t) => {
    setTimeout(() => {
      const info = { page_path: location.pathname, page_title: document.title, page_location: location.href }
      t.$gtag.pageview(info)
    }, 500)
  },
  tarih: (epoch) => {
    // conver epoch to date in turkish locale
    return new Date(epoch * 1000).toLocaleString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  },
  // nl to br
  nl2br: (str) => {
    return str.replace(/\n/g, '<br />')
  }
}
